<template>
  <el-skeleton
    animated
    :throttle="{ leading: 200, trailing: 200, initVal: true }"
    class="elv-dashboard-module-chart-item-skeleton"
  >
    <template #template>
      <el-skeleton-item variant="rect" class="elv-dashboard-module-chart-item-skeleton-background"> </el-skeleton-item>
      <el-skeleton-item variant="rect" class="elv-dashboard-module-chart-item-skeleton-title"> </el-skeleton-item>
      <ChartPreviewSkeleton
        :loading="true"
        :chartType="currentType"
        :isEmptyData="props.isEmptyData"
        emptyIconPosition="20px"
      />
    </template>
    <!-- <template #default>
      <slot name="renderDom"></slot>
    </template> -->
  </el-skeleton>
</template>

<script setup lang="ts">
import ChartPreviewSkeleton from './ChartPreviewSkeleton.vue'

const props = defineProps<{
  chartType: string
  isEmptyData: boolean
}>()

const currentType = toRef(props.chartType)
</script>

<style lang="scss" scoped>
.elv-dashboard-module-chart-item-skeleton {
  height: 424px;
  position: relative;
  border-radius: 8px;
  padding: 24px 24px 20px 16px;
  display: flex;
  flex-direction: column;

  .elv-dashboard-module-chart-item-skeleton-background {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
  }

  .elv-dashboard-module-chart-item-skeleton-title {
    --el-skeleton-color: #ced7e0;
    width: 120px;
    height: 22px;
    margin-bottom: 60px;
    z-index: 1;
  }
}
</style>
