<template>
  <el-skeleton
    animated
    :loading="props.loading"
    :throttle="{ leading: 200, trailing: 200, initVal: true }"
    class="elv-dashboard-screening-chart-menus-skeleton"
    :class="[props.menuClass]"
  >
    <template #template>
      <el-skeleton-item variant="p" />
    </template>
    <template #default>
      <slot name="renderDom"></slot>
    </template>
  </el-skeleton>
</template>

<script setup lang="ts">
const props = defineProps<{
  loading: boolean
  menuClass: string
}>()
</script>

<style lang="scss" scoped>
.elv-dashboard-screening-chart-menus-skeleton {
  --el-skeleton-color: #ced7e0;
  width: 65px;
  height: 15px;

  &.period {
    width: 65px;
  }

  &.date-range {
    width: 80px;
  }

  &.currency {
    width: 115px;
  }

  &.ledger-date-range {
    width: 160px;
  }

  &.check-point {
    width: 165px;
    padding-left: 8px;
    padding-top: 2px;
    position: relative;

    &::before {
      content: '';
      width: 1px;
      height: 15px;
      background-color: #dde1e6;
      position: absolute;
      left: 0px;
      top: 3px;
    }
  }
}
</style>
