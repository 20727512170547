<template>
  <SummaryItemSkeleton :loading="props.pageLoading || props.moduleLoading" :summaryType="props.summaryType">
    <template #renderDom>
      <div
        class="elv-dashboard-summary-item"
        :class="`elv-dashboard-summary-item-${props.summaryType === DashboardModuleType.reports.value ? 'reports' : 'treasury'}`"
      >
        <VerticalTextCell
          :upText="t(summaryMetric)"
          :downText="
            props.summaryData.chartValueList?.length
              ? fieldValueFormat(
                  props.summaryData.chartValueList[0].totalAmountFC,
                  {
                    dollar: true,
                    symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                  },
                  'NUMBER'
                )
              : '-'
          "
          :downDesc="
            fieldValueFormat(
              props.summaryData.chartValueList[0].totalAmountFC,
              {
                price: true,
                keepPoint: true,
                symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              },
              'NUMBER'
            )
          "
          upFontFamily="Plus Jakarta Sans"
          upFontSize="14px"
          :upWeight="500"
          downFontSize="26px"
          :downWeight="600"
        />
        <p v-if="chartUpdateDataTime">{{ chartUpdateDataTime }}</p>
        <el-dropdown
          class="elv-dashboard-summary-item-dropdown"
          popper-class="elv-dashboard-summary-item-dropdown-popper"
          trigger="hover"
          :disabled="!isShowChangeInfoDropdown"
          @command="clickReportSummaryTagItem"
        >
          <SvgIcon
            v-show="isShowChangeInfoDropdown"
            class="elv-dashboard-summary-item-dropdown-link"
            name="more-dian"
            width="14"
            height="14"
            :fill="'#AAAFB6'"
          ></SvgIcon>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="item in ReportSummaryIndicatorList" :key="item.value" :command="item.value">{{
                t(item.label)
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </template>
  </SummaryItemSkeleton>
</template>

<script setup lang="ts">
import { dayjs, ElMessage } from 'element-plus'
import DashboardApi from '@/api/DashboardApi'
import { fieldValueFormat } from '@/lib/utils'
import { useEntityStore } from '@/stores/modules/entity'
import { DashboardChartItemTypes } from '#/DashboardTypes'
import SummaryItemSkeleton from './skeletonItem/SummaryItemSkeleton.vue'
import VerticalTextCell from '@/components/Base/Table/Cell/VerticalTextCell.vue'
import { DashboardModuleType, ReportSummaryIndicatorList, TreasurySummaryIndicatorList } from '@/config/dashboard'

const props = defineProps({
  summaryType: {
    type: String,
    required: true
  },
  summaryData: {
    type: Object as () => DashboardChartItemTypes,
    required: true
  },
  pageLoading: {
    type: Boolean,
    default: false
  },
  moduleLoading: {
    type: Boolean,
    default: false
  },
  isSortTabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['onUpdateSummaryList'])

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const isShowChangeInfoDropdown = computed(() => {
  return (
    props.summaryType === DashboardModuleType.reports.value &&
    !(
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.dashboard?.update
    ) &&
    !props.isSortTabled
  )
})

const summaryMetric = computed(() => {
  return props.summaryType === DashboardModuleType.reports.value
    ? ReportSummaryIndicatorList.find((item) => item.value === props.summaryData.metric)?.label || ''
    : TreasurySummaryIndicatorList.find((item) => item.value === props.summaryData.metric)?.label || ''
})

const chartUpdateDataTime = computed(() => {
  if (!props.summaryData.chartValueList?.length) return ''
  const { date } = props.summaryData.chartValueList[0]
  if (!date?.length) {
    return ''
  }
  const formatDate = date.map((item) => {
    return dayjs(item).format('YYYY/MM/DD')
  })
  return `${formatDate.join('-')}`
})

/**
 * 点击切换Report Summary的指标
 * @param val
 */
const clickReportSummaryTagItem = async (command: string | number | object) => {
  const replaceParams = Object.assign(props.summaryData, { metric: command })
  try {
    await DashboardApi.editChartData(entityId.value, replaceParams.chartId, {
      metric: command,
      name: props.summaryData.name,
      targetType: props.summaryData.targetType,
      showType: props.summaryData.showType
    })
    ElMessage.success(t('message.editSuccess'))
    emit('onUpdateSummaryList')
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}
</script>

<style lang="scss">
.elv-dashboard-summary-item {
  flex: 1;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9fafb;
  border: 1px solid #edf0f3;
  border-radius: 8px;
  position: relative;

  &:first-child {
    margin-left: 0;
  }

  .elv-base-cell-vertical-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .elv-base-cell-vertical-text-up {
      color: #1e2024;
    }

    .elv-base-cell-vertical-text-down {
      color: #1e2024;
      margin-top: 16px;
    }
  }

  p {
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    color: #aaafb6;
    font-weight: 500;
    margin-top: 6px;
  }

  .elv-dashboard-summary-item-dropdown {
    position: absolute;
    top: 15px;
    right: 14px;

    .elv-dashboard-summary-item-dropdown-link {
      cursor: pointer;
    }
  }
}

.elv-dashboard-summary-item-treasury {
  min-width: 392px;
  height: 105px;
}

.elv-dashboard-summary-item-reports {
  min-width: 290px;
  height: 126px;
}

.elv-dashboard-summary-item-dropdown-popper {
  .el-dropdown-menu__item:not(.is-disabled):hover {
    color: #1753eb;
    background-color: #ffffff;
  }
}
</style>
