<template>
  <el-skeleton
    animated
    :loading="props.loading"
    :throttle="{ leading: 100, trailing: 100, initVal: true }"
    class="elv-dashboard-module-chart-preview-skeleton"
    :class="[chartClass]"
  >
    <template #template>
      <div
        v-if="props.isEmptyData"
        class="elv-dashboard-module-chart-preview-skeleton-empty"
        :style="{ 'margin-top': props.emptyIconPosition }"
      >
        <el-skeleton-item variant="rect" class="elv-dashboard-module-chart-preview-skeleton-empty-background" />
        <SvgIcon name="dashboard-empty" :width="48" :height="48" fill="#B3BFCE" />
        <el-skeleton-item variant="text" style="width: 46px; height: 18px" />
      </div>
      <template v-else>
        <div v-if="props.chartType === DashboardChartType.pieChart.type" class="pie-chart-content">
          <div class="pie-chart-content-image">
            <el-skeleton-item variant="rect" />
            <SvgIcon name="dashboard-empty" :width="45" :height="45" fill="#B3BFCE" />
          </div>
          <div class="pie-chart-content-p">
            <el-skeleton-item v-for="item in 10" :key="item" variant="text" style="width: 210px; height: 14px" />
          </div>
        </div>
        <div
          v-else-if="props.chartType === DashboardChartType.barHorizontalChart.type"
          class="bar-horizontal-chart-content"
        >
          <el-skeleton-item v-for="item in 6" :key="item" variant="text" />
        </div>
        <div
          v-else-if="props.chartType === DashboardChartType.barVerticalChart.type"
          class="bar-vertical-chart-content"
        >
          <el-skeleton-item v-for="item in 15" :key="item" variant="text" />
        </div>
        <div v-else class="line-chart-content">
          <el-skeleton-item v-for="item in 8" :key="item" variant="text" />
        </div>
      </template>
    </template>
    <template #default>
      <slot name="renderDom"></slot>
    </template>
  </el-skeleton>
</template>

<script setup lang="ts">
import { DashboardChartType } from '@/config/dashboard'

const props = defineProps({
  chartType: {
    type: String,
    required: true
  },
  isEmptyData: {
    type: Boolean,
    default: false
  },
  emptyIconPosition: {
    type: String,
    default: '25px'
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const chartClass = computed(() => {
  switch (props.chartType) {
    case DashboardChartType.lineChart.type:
      return 'line-chart'
    case DashboardChartType.pieChart.type:
      return 'pie-chart'
    case DashboardChartType.barVerticalChart.type:
      return 'bar-vertical-chart'
    case DashboardChartType.barHorizontalChart.type:
      return 'bar-horizontal-chart'
    default:
      return 'bar-chart'
  }
})
</script>

<style lang="scss" scoped>
.elv-dashboard-module-chart-preview-skeleton {
  position: relative;

  &.line-chart {
    height: 255px;
    position: relative;

    .line-chart-content {
      height: 100%;
      padding: 0px 0px 8px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .el-skeleton__text {
        --el-skeleton-color: #ced7e0;
        height: 14px;
        width: calc(100% - 22px);
      }
    }
  }

  &.pie-chart {
    height: 255px;

    .pie-chart-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;

      .pie-chart-content-image {
        width: 240px;
        height: 240px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .el-skeleton__rect {
          --el-skeleton-color: #ced7e0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }

        svg {
          position: absolute;
        }
      }

      .pie-chart-content-p {
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        .el-skeleton__text {
          --el-skeleton-color: #ced7e0;
        }
      }
    }
  }

  &.bar-vertical-chart {
    height: 255px;

    .bar-vertical-chart-content {
      height: 100%;
      padding: 0px 0px 8px 10px;
      border-left: 1px solid #e4e7eb;
      border-bottom: 1px solid #e4e7eb;
      display: flex;
      justify-content: space-evenly;

      .el-skeleton__text {
        --el-skeleton-color: #ced7e0;
        width: 14px;
        height: 100%;
      }
    }
  }

  &.bar-horizontal-chart {
    height: 255px;

    .bar-horizontal-chart-content {
      height: 100%;
      padding: 0px 0px 8px 20px;
      border-left: 1px solid #e4e7eb;
      border-bottom: 1px solid #e4e7eb;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .el-skeleton__text {
        --el-skeleton-color: #ced7e0;
        height: 14px;
        width: calc(100% - 22px);
      }
    }
  }

  .elv-dashboard-module-chart-preview-skeleton-empty {
    width: 100%;
    height: 255px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .elv-dashboard-module-chart-preview-skeleton-empty-background {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
    }

    svg {
      margin-bottom: 8px;
      z-index: 1;
    }

    .el-skeleton__text {
      --el-skeleton-color: #ced7e0;
      border-radius: 4px;
      z-index: 1;
    }
  }
}
</style>
