<template>
  <div ref="dashboardModuleRef" :key="props.moduleIndex" class="elv-dashboard-module-nav">
    <nav class="elv-dashboard-module-nav-header" @mouseover="isHovered = true" @mouseleave="isHovered = false">
      <DashBoardModuleMoveIcon
        v-show="isHovered && !isUnEnabledMoveModule"
        :disabledMoveUp="props.moduleIndex === 0"
        :disabledMoveDown="props.moduleIndex >= props.moduleListInfo.length - 1"
        :moveUpModule="upMoveModule"
        :moveDownModule="downMoveModule"
      />
      <ChartMenus
        ref="dashboardMenusRef"
        :title="t(props.moduleTypeInfo.label)"
        :moduleTypeInfo="props.moduleTypeInfo"
        @onChangDateTime="onChangDateTime"
        @onChangeLedgerDateTime="onChangeLedgerDateTime"
      />
      <el-button
        v-if="disabledAddBtn && !props.pageLoading && !updateChartListLoading"
        class="elv-dashboard-module-nav-add-chart-btn"
        round
        @click="addNewChart"
      >
        <SvgIcon name="add--alt" :width="16" :height="16" />
        {{ t('button.addChart') }}</el-button
      >
    </nav>
    <div v-if="showSummaryList" class="elv-dashboard-module-nav-body">
      <div v-if="showSummaryList && !!summaryList.length" class="elv-dashboard-module-nav-summary">
        <SummaryItem
          v-for="item in summaryList"
          :key="item.chartId"
          :summaryData="item"
          :pageLoading="props.pageLoading"
          :moduleLoading="updateChartListLoading && !isSortTable"
          :summaryType="props.moduleTypeInfo.value"
          :isSortTabled="isSortTable"
          @onUpdateSummaryList="onUpdateChartList"
        />
      </div>
      <div
        v-if="!!sortableChartList.length"
        ref="dashboardChartListRef"
        class="elv-dashboard-module-nav-chart-list"
        :aria-label="moduleTypeInfo?.value"
      >
        <CharItem
          v-for="item in sortableChartList"
          :key="item.chartId"
          :dashboardModuleType="props.moduleTypeInfo.value"
          :chartData="item"
          :pageLoading="pageLoading"
          :moduleLoading="updateChartListLoading"
          :changePeriodStatus="changePeriodStatus"
          @onDeleteChartItem="onDeleteChartItem"
          @onEditChartItem="onEditChartItem"
        />
      </div>
    </div>
    <div v-else class="elv-dashboard-module-nav-empty">
      <ModuleEmptySkeleton :loading="updateChartListLoading">
        <template #renderDom>
          <div class="elv-dashboard-module-nav-empty-content">
            <SvgIcon name="dashboard-empty" :width="35" :height="35" fill="#DDE1E6" />
            <p>
              {{ t('common.noData') }}<span @click="onJumpLink">{{ moduleEmptyJumpLinkText }}</span>
            </p>
          </div>
        </template>
      </ModuleEmptySkeleton>
    </div>
    <AddChartWidgetDialog
      ref="addChartWidgetDialogRef"
      :dashboardModuleType="props.moduleTypeInfo.value"
      @onClickConfirmHandler="onSaveChartItem"
    />

    <ElvMessageBox
      ref="deleteMessageBoxRef"
      :showCancelButton="true"
      :cancelButtonText="t('button.cancel')"
      :confirmButtonText="t('button.delete')"
      :loading="deleteLoading"
      :title="t('title.deleteChart')"
      @onConfirmEvent="onConfirmDeleteEvent"
      @onCancelEvent="onCheckDeleteMessageBox"
    >
      <template #content>
        <span class="elv-confirm-info-header__title">
          {{ t('message.deleteChartMessage', { name: currentChartData?.name }) }}
        </span>
      </template>
    </ElvMessageBox>
  </div>
</template>

<script setup lang="ts">
import Sortable from 'sortablejs'
import CharItem from './CharItem.vue'
import { ElMessage } from 'element-plus'
import ChartMenus from './ChartMenus.vue'
import SummaryItem from './SummaryItem.vue'
import DashboardApi from '@/api/DashboardApi'
import { useEntityStore } from '@/stores/modules/entity'
import { DashboardModuleType } from '@/config/dashboard'
import { DashboardChartItemTypes } from '#/DashboardTypes'
import AddChartWidgetDialog from './AddChartWidgetDialog.vue'
import { useDashboardStore } from '@/stores/modules/dashboard'
import DashBoardModuleMoveIcon from './DashBoardModuleMoveIcon.vue'
import ModuleEmptySkeleton from './skeletonItem/ModuleEmptySkeleton.vue'

const props = defineProps({
  moduleTypeInfo: {
    type: Object,
    required: true
  },
  moduleIndex: {
    type: Number,
    required: true
  },
  moduleListInfo: {
    type: Array,
    default: () => []
  },
  pageLoading: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['onUpMoveModule', 'onDownMoveModule'])

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const entityStore = useEntityStore()
const dashboardStore = useDashboardStore()

const isHovered = ref(false)
const isSortTable = ref(false)
const deleteLoading = ref(false)
const changePeriodStatus = ref(false)
const updateChartListLoading = ref(false)
const dashboardMenusRef = useTemplateRef('dashboardMenusRef')
const dashboardModuleRef = useTemplateRef('dashboardModuleRef')
const deleteMessageBoxRef = useTemplateRef('deleteMessageBoxRef')
const dashboardChartListRef = useTemplateRef('dashboardChartListRef')
const addChartWidgetDialogRef = useTemplateRef('addChartWidgetDialogRef')
const currentChartData = ref<DashboardChartItemTypes>({} as DashboardChartItemTypes)

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const summaryList = computed(() => {
  if (props.moduleTypeInfo.value === DashboardModuleType.treasury.value) {
    return dashboardStore.treasuryData.fixedChartList
  }
  if (props.moduleTypeInfo.value === DashboardModuleType.reports.value) {
    return dashboardStore.reportsData.fixedChartList
  }
  return []
})

const sortableChartList = computed(() => {
  if (props.moduleTypeInfo.value === DashboardModuleType.treasury.value) {
    return dashboardStore.treasuryData.sortableChartList
  }
  if (props.moduleTypeInfo.value === DashboardModuleType.reports.value) {
    return dashboardStore.reportsData.sortableChartList
  }
  if (props.moduleTypeInfo.value === DashboardModuleType.ledger.value) {
    return dashboardStore.ledgerData
  }
  return []
})

const isUnEnabledMoveModule = computed(() => {
  return (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.dashboard?.update
  )
})

const showSummaryList = computed(() => {
  if (props.moduleTypeInfo.value === DashboardModuleType.ledger.value) {
    return sortableChartList.value.length > 0
  }
  return (
    !!summaryList.value.length &&
    summaryList.value.some((summary) => summary?.chartValueList?.[0]?.totalAmountFC !== null)
  )
})

const disabledAddBtn = computed(() => {
  return (
    !(
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.dashboard?.update
    ) && showSummaryList.value
  )
})

const moduleEmptyJumpLinkText = computed(() => {
  if (props.moduleTypeInfo?.value === 'TREASURY_BALANCE') {
    return t('button.addAccountsInTreasury')
  }
  if (props.moduleTypeInfo?.value === 'REPORT') {
    return t('button.generateReportsInReports')
  }
  return t('button.addChart')
})

const upMoveModule = () => {
  emit('onUpMoveModule', props.moduleIndex)
}

const downMoveModule = () => {
  emit('onDownMoveModule', props.moduleIndex)
}

/**
 * 添加新的Chart表格
 */
const addNewChart = () => {
  addChartWidgetDialogRef.value?.changeDialogStatus()
}

const onJumpLink = () => {
  if (props.moduleTypeInfo.value === DashboardModuleType.ledger.value) {
    addNewChart()
  } else {
    router.push({
      name: props.moduleTypeInfo?.value === 'TREASURY_BALANCE' ? 'entity-accounts-treasury' : 'reports'
    })
  }
}

/**
 * 编辑Chart表格
 * @param chartData 图表数据
 */
const onEditChartItem = (editData: any) => {
  addChartWidgetDialogRef.value?.changeDialogStatus(editData?.data, editData?.timeRange)
}

/**
 * 更新Chart表格
 */
const onUpdateChartList = async () => {
  updateChartListLoading.value = true
  try {
    if (props.moduleTypeInfo.value === DashboardModuleType.treasury.value) {
      await dashboardStore.fetchTreasuryData(entityId.value)
    }
    if (props.moduleTypeInfo.value === DashboardModuleType.reports.value) {
      const params = dashboardStore.getCurrentReportFilterInfo(entityId.value)
      await dashboardStore.fetchReportsData(entityId.value, params)
    }
    if (props.moduleTypeInfo.value === DashboardModuleType.ledger.value) {
      const params = dashboardStore.getCurrentLedgerFilterInfo(entityId.value)
      await dashboardStore.fetchLedgerData(entityId.value, params)
    }
  } catch (error: any) {
    if (error.message !== 'canceled' && error?.code !== -200) {
      ElMessage.error(error.message)
    }
  } finally {
    updateChartListLoading.value = false
  }
}

/**
 * 保存Chart表格
 */
const onSaveChartItem = async () => {
  onUpdateChartList()
}

/**
 * @description: 切换删除弹窗状态
 */
const onCheckDeleteMessageBox = () => {
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * 删除Chart表格
 * @param chartId 图表数据
 */
const onDeleteChartItem = (chartData: DashboardChartItemTypes) => {
  currentChartData.value = chartData
  onCheckDeleteMessageBox()
}

/**
 * @description: 删除规则确认
 */
const onConfirmDeleteEvent = async () => {
  try {
    deleteLoading.value = true
    await DashboardApi.deleteChartData(entityId.value, currentChartData.value?.chartId)
    ElMessage.success(t('message.deleteSuccess'))
    onCheckDeleteMessageBox()
    onUpdateChartList()
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}

/**
 * Ledger模块日期筛选变化时触发
 */
const onChangeLedgerDateTime = () => {
  onUpdateChartList()
}

/**
 * 日期筛选变化时触发
 * @param data 日期
 */
const onChangDateTime = async (data: any) => {
  const changePeriodInfo = dashboardStore.getCurrentReportFilterInfo(entityId.value)
  if (!changePeriodInfo) {
    changePeriodStatus.value = !changePeriodStatus.value
  }
  if (changePeriodInfo?.period !== data?.period) {
    changePeriodStatus.value = !changePeriodStatus.value
  }
  onUpdateChartList()
}

/**
 * @description: 初始化拖拽排序
 */
const initSortable = async () => {
  nextTick(() => {
    const chartModuleList = dashboardModuleRef.value?.querySelectorAll('.elv-dashboard-module-nav-chart-list')
    let originalOrder: any
    const element: any = chartModuleList ? chartModuleList[0] : null
    if (!element) return
    const type = element?.getAttribute('aria-label')
    Sortable.create(element, {
      animation: 300,
      handle: '.elv-dashboard-chart-item-title-draggable__icon',
      ghostClass: 'elv-dashboard-chart-item__sortable-ghost',
      dragClass: 'elv-dashboard-chart-item__sortable-drag',
      onStart: () => {
        if (
          ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
          !currentEntityPermission.value?.dashboard?.update
        ) {
          ElMessage.warning(t('message.noPermission'))
          return
        }
        isSortTable.value = true
        originalOrder = Array.from(element.children)
      },
      onEnd: async ({ newIndex, oldIndex }) => {
        if (oldIndex !== newIndex) {
          try {
            let newList: DashboardChartItemTypes[] = []
            updateChartListLoading.value = true
            const dataMapping: any = {
              TREASURY_BALANCE: () => dashboardStore.treasuryData.sortableChartList.slice(),
              LEDGER: () => dashboardStore.ledgerData.slice(),
              REPORT: () => dashboardStore.reportsData.sortableChartList.slice()
            }
            const fetchData = dataMapping[type]
            if (!fetchData) return
            newList = await fetchData()
            newList.splice(Number(newIndex), 0, newList.splice(Number(oldIndex), 1)[0])
            const chartIds = newList.map((item) => {
              return item?.chartId
            })
            await DashboardApi.sortChartDataList(entityId.value, { chartIds, sourceType: type })
            ElMessage.success(t('message.sorted'))
            await onUpdateChartList()
            updateChartListLoading.value = false
            isSortTable.value = false
          } catch (error: any) {
            updateChartListLoading.value = false
            isSortTable.value = false
            // eslint-disable-next-line no-param-reassign
            element.innerHTML = '' // 清空表格内容
            for (const row of originalOrder) {
              element.appendChild(row)
            }
            ElMessage.error(error.message)
          }
        }
      }
    })
  })
}

watch(
  () => dashboardChartListRef.value,
  (newChartList) => {
    if (newChartList) {
      initSortable()
    }
  },
  { deep: true }
)

const initModuleData = async () => {
  await onUpdateChartList()
  initSortable()
}

onMounted(initModuleData)
</script>

<style lang="scss" scoped>
.elv-dashboard-module-nav {
  margin-top: 25px;

  .elv-dashboard-module-nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: visible;
    padding-left: 18px;

    .elv-dashboard-module-nav-add-chart-btn {
      width: 102px;
      height: 32px;
      border: 1px solid #c8d6fa;
      color: #1753eb;
      font-size: 13px;
      font-weight: 500;

      svg {
        margin-right: 6px;
      }
    }
  }

  .elv-dashboard-module-nav-summary {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .elv-dashboard-module-nav-chart-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 16px;
  }

  .elv-dashboard-module-nav-body {
    padding-left: 18px;
  }

  .elv-dashboard-module-nav-empty {
    height: 231px;
    position: relative;

    .elv-dashboard-module-nav-empty-content {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 16px;
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 8px;
      background: #f9fafb;
      margin-left: 18px;

      p {
        margin-top: 16px;
      }

      span {
        color: #1753eb;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        cursor: pointer;
        margin-left: 6px;
      }
    }
  }
}
</style>
