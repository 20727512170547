<template>
  <el-skeleton
    :loading="props.loading"
    animated
    :throttle="{ leading: 200, trailing: 200, initVal: true }"
    :class="['elv-dashboard-module-summary-item-skeleton', currentSummaryClass]"
  >
    <template #template>
      <el-skeleton-item variant="rect" class="elv-dashboard-module-summary-item-skeleton-background">
      </el-skeleton-item>
      <div class="elv-dashboard-module-summary-item-skeleton-detail">
        <el-skeleton-item variant="p" class="elv-dashboard-module-summary-item-skeleton-handler"> </el-skeleton-item>
        <el-skeleton-item variant="p" class="elv-dashboard-module-summary-item-skeleton-handler"> </el-skeleton-item>
      </div>
    </template>
    <template #default>
      <slot name="renderDom"></slot>
    </template>
  </el-skeleton>
</template>

<script setup lang="ts">
import { DashboardModuleType } from '@/config/dashboard'

const props = defineProps<{
  loading: boolean
  summaryType: string
}>()

const currentSummaryClass = computed(() => {
  return props.summaryType === DashboardModuleType.reports.value ? 'report' : 'treasury'
})
</script>

<style lang="scss" scoped>
.elv-dashboard-module-summary-item-skeleton {
  flex: 1;
  min-height: 0px;
  position: relative;
  border-radius: 8px;
  margin-left: 16px;

  &:first-child {
    margin-left: 0px;
  }

  &.report {
    min-width: 290px;
    height: 126px;
  }

  &.treasury {
    min-width: 392px;
    height: 105px;
  }

  .elv-dashboard-module-summary-item-skeleton-background {
    width: 100%;
    height: 100%;
  }

  .elv-dashboard-module-summary-item-skeleton-detail {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;

    .elv-dashboard-module-summary-item-skeleton-handler {
      --el-skeleton-color: #ced7e0;
      border-radius: 4px;

      &:first-child {
        width: 90px;
        height: 20px;
      }

      &:last-child {
        width: 100px;
        height: 32px;
        margin-top: 16px;
      }
    }
  }
}
</style>
