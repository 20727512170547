<template>
  <el-skeleton
    animated
    :loading="props.loading"
    :throttle="{ leading: 200, trailing: 200, initVal: true }"
    class="elv-dashboard-module-empty-skeleton"
  >
    <template #template>
      <el-skeleton-item variant="rect" class="elv-dashboard-module-empty-skeleton-background"> </el-skeleton-item>
      <div class="elv-dashboard-module-empty-skeleton-detail">
        <SvgIcon name="dashboard-empty" :width="35" :height="35" fill="#DDE1E6" />
        <el-skeleton-item variant="p" class="elv-dashboard-module-empty-skeleton-handler"> </el-skeleton-item>
      </div>
    </template>
    <template #default>
      <slot name="renderDom"></slot>
    </template>
  </el-skeleton>
</template>
<script setup lang="ts">
const props = defineProps<{
  loading: boolean
}>()
</script>
<style lang="scss" scoped>
.elv-dashboard-module-empty-skeleton {
  width: 100%;
  height: 231px;
  margin: 16px 0px 0px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: relative;

  .elv-dashboard-module-empty-skeleton-background {
    width: 100%;
    height: 100%;
    // --el-skeleton-color: #ced7e0;
  }

  .elv-dashboard-module-empty-skeleton-detail {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      width: 35px;
      height: 35px;
    }

    .elv-dashboard-module-empty-skeleton-handler {
      --el-skeleton-color: #ced7e0;
      margin-top: 16px;
      width: 110px;
      height: 15px;
    }
  }
}
</style>
